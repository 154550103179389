export const MENU_ITEMS = [
    {
        title: 'AI ChatBot',
        href: route('index') + '#chat-ai',
    },
    {
        title: 'Popular Queries',
        href: route('index') + '#popular-queries',
    },
    {
        title: 'Reviews',
        href: route('index') + '#reviews',
    },
    {
        title: 'AI Features',
        href: route('index') + '#ai-features',
    },
    {
        title: 'AI Solutions',
        href: route('index') + '#ai-solutions',
    },
];

export const FOOTER_MENU_ITEMS = [
    {
        title: 'AI ChatBot',
        href: route('index') + '#chat-ai',
    },
    {
        title: 'AI Features',
        href: route('index') + '#ai-features',
    },
    {
        title: 'Popular Queries',
        href: route('index') + '#popular-queries',
    },
    {
        title: 'AI Solutions',
        href: route('index') + '#ai-solutions',
    },
    {
        title: 'Reviews',
        href: route('index') + '#reviews',
    },
    {
        title: 'Billing Terms',
        href: route('documents', {docType: 'billing'}),
    },
    {
        title: 'Terms of Use',
        href: route('documents', {docType: 'tou'}),
    },
    {
        title: 'Privacy Policy',
        href: route('documents', {docType: 'pp'}),
    },
    {
        title: 'Refund Policy',
        href: route('documents', {docType: 'refund'}),
    },
];

export const START_NOW_LINK = route('register');
